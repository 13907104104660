function Footer() {
	return (
		<div className="footer">
			<p>
				Besoin d’aide, d’une info ? Envoyez vos questions sur l’adresse
				mail{" "}
				<a href="mailto:jobexplorer@eedk.fr">jobexplorer@eedk.fr</a>
			</p>
		</div>
	)
}
export default Footer
